import Errors, { withErrors } from '../errors'
import { FormProvider, useForm } from "react-hook-form"
import { gql, useMutation } from '@apollo/client'
import { isLoggedIn, loginExpired, setCurrentEditor } from '../../services/authentication'

import Input from '../form/input'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import Notice from '../notice'
import React from 'react'
import donut from '../../images/donut.svg'
import { navigate } from 'gatsby'
import { useTitle } from '../browser'

const LOGIN_EDITOR_MUTATION = gql`
  mutation LoginEditor($login: String!, $password: String!) {
    loginEditor(input: { login: $login, password: $password }) {
      jwt
      editor {
        id
        displayName
        username
      }
    }
  }
`

const Login = () => {
  useTitle('Login')
  const methods = useForm()
  const [loginEditor, { loading, error }] = useMutation(LOGIN_EDITOR_MUTATION, {
    onCompleted({ loginEditor }) {
      window.localStorage.setItem("jwt", loginEditor.jwt)
      setCurrentEditor({ ...loginEditor.editor })
      window.location = '/recipes'
    },
    onError(error) {
      console.error('error', error);
    }
  })

  if (isLoggedIn()) {
    navigate('/recipes')
  }

  const onFormSubmit = data => {
    loginEditor({ variables: data })
  }

  return (
    <FormProvider {...methods} >
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-sm w-full space-y-8">
          <div>
            <img
              className="mx-auto h-16 w-auto"
              src={donut}
              alt="Zucker&Jagdwurst"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Please sign in</h2>
          </div>
          {( loginExpired() && (
            <Notice>
              Your session expired. Please log in again. <span role="img" aria-label="Hotdog Emoji">🌭</span>
            </Notice>
          ))}
          {( error && <Errors errors={withErrors(error)}/>)}
          <form className={loading ? 'pointer-events-none mt-8 space-y-6' : 'mt-8 space-y-6'} onSubmit={methods.handleSubmit(onFormSubmit)}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm space-y-2">
              <div>
                <Input
                  label="Email Address"
                  name="login"
                  register={methods.register}
                />
              </div>
              <div>
                <Input
                  label="Password"
                  name="password"
                  register={methods.register}
                  type="password"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                { loading ? 'Signing in...' : 'Sign in'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </FormProvider>
  )
}

export default Login
