import { InformationCircleIcon } from '@heroicons/react/24/outline'
import React from 'react'

const Notice = ({ notice='', children }) => (
  <div className="rounded-md bg-blue-50 p-4 border-2 border-blue-500">
    <div className="flex">
      <div className="flex-shrink-0">
        <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <p className="text-sm text-blue-700">{notice}{children}</p>
      </div>
    </div>
  </div>
)

export default Notice
