export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function toggleItemInArray(arr, item) {
    if (typeof item === 'object') {
      if (arr.find(i => i.id === item.id)) return arr.filter(i => i.id !== item.id)
      else return [...arr, item]
    }
    if (arr.includes(item)) return arr.filter(i => i !== item)
    else return [...arr, item]
}

export function array_move(arr, old_index, new_index) {
  const copiedArr = [...arr]
  if (new_index >= copiedArr.length) {
    var k = new_index - copiedArr.length + 1
    while (k--) {
      copiedArr.push(undefined)
    }
  }
  copiedArr.splice(new_index, 0, copiedArr.splice(old_index, 1)[0])
  return copiedArr // for testing
}

export function slugify(text) {
  return text
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .replace(/ä/, 'ae')
    .replace(/ü/, 'ue')
    .replace(/ö/, 'oe')
    .replace(/ß/, 'ss')
    .replace(/!/, '')
    .replace(/\?/, '')
    .replace(/ /, '-')
    .normalize('NFD')       // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim()                         // Remove whitespace from both sides of a string
    .replace(/-+/g, '-');        // Replace multiple - with single -
}
